<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
// import { useI18n } from "vue-i18n";

import { dateFormatter, numberFormatter } from "@/utils/formatters";
import { store } from "@/store";
import useApi from "@/composables/useApi";

import AppCard from "@/components/AppCard";
import AppRibbon from "@/components/AppRibbon";
import AppRow from "@/components/AppRow";
import AppDescription from "@/components/AppDescription";
import AppCheckbox from "@/components/Form/AppCheckbox";
import AppAlert from "@/components/AppAlert";
//
import AppSubscriptionForm from "@/components/Payment/AppSubscriptionForm";
import AppCheckoutForm from "@/components/Payment/AppCheckoutForm";

import AppButton from "@/components/AppButton";

const route = useRoute();
const { service, invoice } = useApi();
// const { t } = useI18n();
const checkvalue = ref(false);
const invoiceData = ref(null);
const service_id = ref(null);
const serviceData = ref(null);
const lastInvoiceId = ref(null);
const countInvoicesPending = ref(null);
const is_last_invoice = ref(false);
const loading = ref(true);
const subscription_exists = ref(false);
const allowSubscription = ref(false);

const getService = async (id) => {
  const data = await service.getServiceById(id);
  return data.payload[0];
};

const getInvoice = async (id) => {
  const data = await invoice.getInvoiceById(id);
  return data.payload[0];
};

const getCountInvoicesPendingByService = async (service_id) => {
  const data = await invoice.countInvoicesPendingByService(service_id);
  return data.payload;
};

const getLastInvoiceId = async (id) => {
  const data = await invoice.getLastInvoiceId(id);
  return data.payload[0];
};

const getAllowSubscription = async () => {
  // Validaciones para suscribir
  if ((invoiceData.value.payment_state === 'not_paid' || invoiceData.value.payment_state === 'partial') && is_last_invoice.value && countInvoicesPending.value === 1) {
    return true;
  }
  return false;
};

onMounted(async () => {
  try {
    const { id } = route.params;
    store.APP_LOADING = true;
    store.I118N_TITLE = false;
    store.APP_TITLE = "";
    invoiceData.value = await getInvoice(id);
    store.APP_TITLE = invoiceData.value.name;
    service_id.value = invoiceData.value.servicio_id[0];
    serviceData.value = await getService(service_id.value);
    lastInvoiceId.value = await getLastInvoiceId(service_id.value);
    if (invoiceData.value.id === lastInvoiceId.value.id) {
      is_last_invoice.value = true;
    }
    countInvoicesPending.value = await getCountInvoicesPendingByService(service_id.value);
    subscription_exists.value = serviceData.value.subscription_status;
    allowSubscription.value = await getAllowSubscription();
    console.log(allowSubscription.value);
  } catch (error) {
    console.log(error);
  } finally {
    store.APP_LOADING = false;
  }
});
</script>
<template lang="pug">
.cards
  //- pre {{ countInvoicesPending }}
  //- pre {{ is_last_invoice }}
  //- pre {{ serviceData }}
  //- pre {{ invoiceData }}
  .cards__body
    AppCard(v-if="invoiceData")
      AppRibbon(v-if="invoiceData.payment_state === 'paid'" type="success" :label="$t('invoices.paid')")
      .invoice__name {{ invoiceData.name }}
      AppRow.mb-4
        AppDescription(:label="$t('invoices.partner_id')" :description="invoiceData.partner_id[1]")
        AppDescription(:label="$t('invoices.servicio_id')" :description="invoiceData.servicio_id[1]")
      AppRow.mb-4
        AppDescription(:label="$t('invoices.invoice_date')" :description="invoiceData.invoice_date")
        AppDescription(:label="$t('invoices.invoice_payment_term_id')" :description="invoiceData.invoice_payment_term_id[1]" v-if="invoiceData.invoice_payment_term_id")
      AppRow.mb-4
        AppDescription(:label="$t('invoices.amount_total')" :description="numberFormatter(invoiceData.amount_total)")
        AppDescription(:label="$t('invoices.amount_residual')" :description="numberFormatter(invoiceData.amount_residual)")
      AppRow.mb-4
        AppDescription(:label="$t('invoices.state')" :description="invoiceData.state")
      div(v-if="subscription_exists")
        AppAlert.mb-4(:title="$t('payment.subscription.title')" :subtitle="$t('payment.subscription.subtitle')" type="info")
      div(v-else)
        div(v-if="allowSubscription")
          AppCheckbox(v-model="checkvalue" :label="$t('invoices.confirm')" )
          AppRow
            AppSubscriptionForm(
              :invoice="invoiceData" 
              :service="serviceData" 
              :name="invoiceData.name" 
              :disabled="!checkvalue"
            )
        div(v-else)
          AppAlert.mb-4(:title="$t('payment.subscription_disabled.title')" :subtitle="$t('payment.subscription_disabled.subtitle')" type="info")
</template>

<style lang="scss" scoped>
.invoice__name {
  @apply text-xs;
  @apply text-bluegray-400;
  @apply mb-2;
  @apply font-medium;
}

.label {
  @apply text-sm;
  @apply font-medium;
  @apply mb-2;
  @apply inline-block;
  @apply tracking-wide;
}
</style>
