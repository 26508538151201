<template lang="pug">
//- pre | {{ formData }}
form(:action="pathCheckout" method="POST")
  input(type="text" name="currency" class="hidden"  	  v-model="formData.currency")
  input(type="text" name="name" class="hidden"  			  v-model="formData.name")
  input(type="text" name="description" class="hidden"  	v-model="formData.description")
  input(type="text" name="reference" class="hidden"     v-model="formData.reference")
  input(type="text" name="id" class="hidden"            v-model="formData.id")
  input(type="text" name="partner_id" class="hidden"    v-model="formData.partner_id")
  input(type="text" name="partner_name" class="hidden"  v-model="formData.partner_name")
  input(type="text" name="mode" class="hidden"          v-model="formData.mode")
  input(type="text" name="unit_amount" class="hidden"  	v-model="formData.unit_amount")
  input(type="text" name="interval" class="hidden"  	  v-model="formData.interval")
  input(type="text" name="quantity" class="hidden"  	  v-model="formData.quantity")
  AppButton(:label="$t('quickpayment.subscribe')" :disabled="disabled || service.subscription_status || invoice.payment_state === 'paid'")
  
</template>

<script>
import { reactive } from "@vue/reactivity";
import AppCheckbox from "@/components/Form/AppCheckbox";
import AppButton from "@/components/AppButton";
export default {
  components: {
    AppButton,
    AppCheckbox,
  },
  props: {
    name: String,
    invoice: [Object, null],
    service: [Object, null],
    disabled: Boolean,
  },
  setup(props) {
    const pathCheckout = process.env.VUE_APP_SUBSCRIPTION_URL;
    const formData = reactive({
      currency: "mxn",
      name: props.name,
      description: props.service.plan_id[1],
      reference: props.invoice.servicio_id[1].toUpperCase(),
      id: props.service.id,
      partner_id: props.invoice.partner_id[0],
      partner_name: props.invoice.partner_id[1],
      mode: "subscription",
      unit_amount: Math.ceil(props.invoice.amount_residual.toFixed(2) * 100),
      interval: "month",
      quantity: 1,
    });
    return { formData, pathCheckout };
  },
};
</script>

<style></style>
